// Swiper 7.4.1
import './vendor/swiper';
import './vendor/focus-visible-polyfill';

/* eslint-disable*/

window.addEventListener('DOMContentLoaded', () => {

const swiperInfo = new Swiper('.info__swiper', {
  spaceBetween: 1,
  slidesPerView: 1,
  speed: 600,
  autoHeight: true,
  direction: 'horizontal',
  loop: false,
  navigation: {
    nextEl: '.info__button-next',
    prevEl: '.info__button-prev',
  },
  pagination: {
    el: '.info__pagination',
    clickable: true,
  },
  autoHeight: true,
});

const swiperComments = new Swiper('.comments__swiper', {
  spaceBetween: 1,
  slidesPerView: 1,
  speed: 600,
  autoHeight: true,
  direction: 'horizontal',
  loop: false,
  navigation: {
    nextEl: '.comments__button-next',
    prevEl: '.comments__button-prev',
  },
  pagination: {
    el: '.comments__pagination',
    clickable: true,
  },
});

const swiperClosing = new Swiper('.closing__swiper', {
  spaceBetween: 1,
  slidesPerView: 1,
  speed: 600,
  autoHeight: true,
  direction: 'horizontal',
  loop: false,
  navigation: {
    nextEl: '.closing__button-next',
    prevEl: '.closing__button-prev',
  },
  pagination: {
    el: '.closing__pagination',
    clickable: true,
  },
});

});
